* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --theme-color: rgb(79, 26, 122);
  --primary-color: #ffffff;
}
.second-card {
  --theme-color: #fff;
  --primary-color: #000;
}
body {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a {
  text-decoration: none;
  display: inline-block;
}
.theme-btn {
  font-size: 4vw;
  font-weight: 600;
  color: var(--theme-color);
  background-color: var(--primary-color);
  text-transform: capitalize;
  padding: 1.4vw 5.6vw;
  border-radius: 4vw;
}
.b-close {
  position: absolute;
  top: 2vw;
  right: 2vw;
  z-index: 1;
  width: 2vw;
  height: 2vw;
  padding: 0;
  background-color: var(--primary-color);
  background-size: 21px;
  opacity: 1;
}
img {
  object-fit: cover;
}
.card-wrapper {
  min-height: 100vh;
}
.card-wrapper .card-container {
  background-color: var(--theme-color);
  min-height: 100vh;
  padding: 14vw 4vw 8vw;
}
.card-wrapper .card-container .card-dtl {
  padding-bottom: 5vw;
}
.card-wrapper .card-container .card-dtl .businessmen-img {
  width: 28vw;
  height: 28vw;
  border-radius: 100%;
  margin: auto;
  overflow: hidden;
  padding: 3vw;
}
.card-wrapper .card-container .card-dtl .businessmen-img img {
  width: 100%;
  height: 100%;
}
.card-wrapper .card-container .card-dtl .businessman-name {
  color: var(--primary-color);
  padding: 3vw 0 1.4vw;
  font-size: 6vw;
  font-weight: 400;
  text-transform: capitalize;
}
.card-wrapper .card-container .card-dtl h6 {
  color: var(--primary-color);
  font-weight: 400;
  padding-bottom: 1.2vw;
  font-size: 3.6vw;
}
.card-wrapper .card-container .card-contact .contact-items {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  /* margin-bottom: 2rem; */
  /* height: 24vw; */
}
.card-wrapper .card-container .card-contact .contact-items .card-item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  height: 24vw;
  flex: 0 0 33.33%;
}
.card-wrapper
  .card-container
  .card-contact
  .contact-items
  .card-item
  .icon-img {
  display: block;
  width: 11.4vw;
}
.card-wrapper .card-container .card-contact .contact-items .card-item p {
  color: var(--primary-color);
  font-size: 3vw;
  padding-top: 1.2vw;
  text-transform: capitalize;
  text-align: center;
  line-height: 1.25;
  font-family: "Noto Sans", sans-serif;
}

/*  card-follow  */
.card-wrapper .card-follow {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.card-wrapper .card-follow .follow-me-btn {
  display: table;
  margin: -7.4vw auto 0;
  background: var(--primary-color);
  text-transform: capitalize;
  font-size: 3.5vw;
  position: relative;
  padding: 0.5vw 5vw;
  border-radius: 24px;
  font-weight: 600;
  box-shadow: 0 0 5px;
}
.card-wrapper .card-follow .social-icons {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  gap: 3vw;
  padding: 6.6vw 3vw 4vw;
  border-radius: 6vw 6vw 0 0;
  background-color: var(--primary-color);
}
.card-wrapper .card-follow.active .social-icons {
  position: initial;
  margin-top: -3.2vw;
}
.card-wrapper .card-follow .social-icons .social-item {
  display: block;
}
.card-wrapper .card-follow .social-icons .social-item img {
  width: 10vw;
}

/*  branch-wrapper  */
.card-wrapper .branch-wrapper .branch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: table;
  width: fit-content;
  margin: auto;
  padding: 0 4vw 0.6vw;
  font-size: 4vw;
  font-weight: 600;
  z-index: 2;
  border-radius: 0 0 10px 10px;
  background-color: var(--primary-color);
}
.card-wrapper .branch-wrapper .branch img {
  width: 3.2vw;
  margin-left: 1vw;
  vertical-align: -0.3vw;
}
.card-wrapper .branch-wrapper .branch-name {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  width: calc(100% - 8vw);
  margin: 8vw auto 0;
  max-height: calc(100vh - 9vh);
  overflow: auto;
  z-index: 1;
  padding: 5vw;
  gap: 2.6vw;
  border-radius: 3.2vw;
  /* background-color: var(--primary-color); */
  background-color: #2e5260;
  transition: all 0.5s ease-in-out;
}
.card-wrapper .branch-wrapper .branch-name.active {
  top: 0;
}
.card-wrapper .branch-wrapper .branch-name p {
  font-size: 3.8vw;
  padding: 1vw 4vw;
  font-weight: 500;
  background-color: var(--theme-color);
  display: inline-block;
  color: var(--primary-color);
  border-radius: 6vw;
}
.card-wrapper .branch-wrapper .branch-name p:last-child {
  border: none;
}
.card-wrapper .branch-wrapper .branch-name .branch-search {
  margin-bottom: 4vw;
  top: 0;
}
.card-wrapper .branch-wrapper .branch-name .branch-search input {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10vw;
  padding: 1.2vw 3.8vw;
  font-size: 3.6vw;
  width: 100%;
}
.card-wrapper .branch-wrapper .branch-name .branch-search input:focus-visible {
  outline: none;
}
.card-wrapper .branch-wrapper .branch-name .branch-search button {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 3vw;
  top: 50%;
  line-height: 0.8;
  transform: translateY(-50%);
}
.card-wrapper .branch-wrapper .branch-name .branch-search button img {
  width: 4vw;
}

/*  modal  */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  transition: all 0.4s ease-in-out;
}
.modals {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 10vw);
  margin: auto;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
  z-index: 4;
  transition: all 0.4s ease-in-out;
}
body.active .overlay,
.modals.active {
  opacity: 1;
  visibility: visible;
}
.modals .btn-close {
  top: -2.6vw;
  right: -2.6vw;
}
.modals .modal-slider {
  border-radius: 6vw;
  overflow: hidden;
}
.modals .modal-slider .modal-item img {
  width: 100%;
  height: 100%;
}

/*  about-wrapper  */
.about-wrapper {
  /* position: fixed;
  top: 100%;
  left: 0; */
  /* width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto; */
  /* background-color: var(--theme-color); */
  z-index: 4;
  padding: 2vw 5vw 5vw;
  transition: all 0.4s ease-in-out;
}
.about-wrapper.active {
  top: 0;
}
.about-wrapper .theme-ttl {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 6vw;
}
.about-wrapper .profile {
  padding-bottom: 7vw;
}
.about-wrapper .profile img {
  width: 40vw;
  height: 40vw;
  border-radius: 4vw;
  margin: auto;
  display: block;
  border: 1px solid;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
}
.about-wrapper .about-dtl h4 {
  font-size: 6vw;
  font-weight: 600;
  color: var(--primary-color);
  padding-bottom: 4vw;
}
.about-wrapper .about-dtl p {
  padding-bottom: 3vw;
  color: var(--primary-color);
  font-size: 4vw;
}

.about-wrapper .about-dtl .about-data p {
  color: black;
}
.about-wrapper .about-dtl .theme-btn {
  margin-top: 3vw;
}

/*  inquiry-form  */
.inquiry-modal .modal-content form .form-item {
  margin-bottom: 4vw;
}
.inquiry-modal .modal-content form .form-item .form-control {
  font-size: 4vw;
  padding: 1.8vw 3vw;
  letter-spacing: 0.2vw;
}
.inquiry-modal .modal-content .modal-header .btn-close {
  filter: invert(14%) sepia(89%) saturate(4932%) hue-rotate(2deg)
    brightness(96%) contrast(130%);
  opacity: 1;
  font-size: 4.6vw;
  top: 3.3vw;
  right: 3vw;
}
/* #inquiry-form.modal .modal-dialog .modal-content {
  background-color: var(--theme-color);
  border: none;
  border-radius: 3vw;
}
#inquiry-form.modal .modal-dialog .modal-content .modal-title {
  text-transform: capitalize;
  color: var(--primary-color);
  font-size: 6.4vw;
  padding: 2vw 5vw 0;
}
#inquiry-form.modal .modal-dialog .modal-content .modal-body {
  padding: 3vw 5vw 6vw;
}
#inquiry-form.modal .modal-dialog .modal-content .modal-body form .form-item {
  margin-bottom: 3.8vw;
}
#inquiry-form.modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .form-item
  label {
  color: var(--primary-color);
  text-transform: capitalize;
  font-size: 4vw;
  margin-bottom: 1vw;
}
#inquiry-form.modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .form-item
  .form-control {
  font-size: 4vw;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 2.5vw;
  box-shadow: none;
  outline: none;
  padding: 1.3vw 3.4vw;
  color: #000;
}
#inquiry-form.modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .form-item
  .form-control:focus {
  background-color: var(--primary-color);
}
#inquiry-form.modal .modal-dialog .modal-content .modal-body form .theme-btn {
  border: none;
  display: table;
  margin: auto;
} */
.copyrite {
  white-space: nowrap;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px 4px;
  border-radius: 14px 14px 0 0;
  background-color: #fff;
}
@media (min-width: 767px) {
  .card-wrapper .card-container .card-contact .contact-items .card-item {
    flex: 0 0 25%;
  }
}
@media (min-width: 992px) {
  .card-wrapper .card-container .card-contact .contact-items .card-item {
    flex: 0 0 20%;
  }
}
@media (max-width: 575px) {
  .card-wrapper .card-follow .social-icons .social-item img {
    width: 7vw;
  }
  .card-wrapper .card-follow .social-icons {
    padding: 6.6vw 3vw 7vw;
  }
}
@media (min-width: 575px) {
  .card-wrapper {
    max-width: 575px;
    max-width: 100%;
    margin: auto;
  }

  .card-wrapper .branch-wrapper .branch {
    font-size: 20px;
    padding: 0px 25px 3px;
    cursor: pointer;
  }
  .card-wrapper .branch-wrapper .branch img {
    width: 14px;
    margin-left: 5px;
    vertical-align: -2px;
  }
  .card-wrapper .branch-wrapper .branch-name {
    width: calc(575px - 40px);
    margin-top: 44px;
    padding: 20px;
    gap: 12px;
    border-radius: 18px;
  }
  .card-wrapper .branch-wrapper .branch-name::-webkit-scrollbar,
  /* #inquiry-form.modal
    .modal-dialog
    .modal-content
    .modal-body::-webkit-scrollbar {
    width: 8px;
  } */
  .card-wrapper .branch-wrapper .branch-name::-webkit-scrollbar-thumb,
  /* #inquiry-form.modal
    .modal-dialog
    .modal-content
    .modal-body::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
    border: 2px solid #000;
    border-radius: 10px;
  } */
  .card-wrapper .branch-wrapper .branch-name .branch-search {
    margin-bottom: 10px;
  }
  .card-wrapper .branch-wrapper .branch-name .branch-search input {
    font-size: 18px;
    padding: 8px 20px;
  }
  .card-wrapper .branch-wrapper .branch-name .branch-search button {
    right: 20px;
  }
  .card-wrapper .branch-wrapper .branch-name .branch-search button img {
    width: 20px;
  }
  .card-wrapper .branch-wrapper .branch-name p {
    font-size: 18px;
    padding: 4px 20px;
    cursor: pointer;
  }
  .card-wrapper .card-container {
    padding: 65px 30px;
  }
  .card-wrapper .card-container .card-dtl {
    padding-bottom: 30px;
  }
  .card-wrapper .card-container .card-dtl .businessmen-img {
    width: 150px;
    height: 150px;
    padding: 0px;
  }
  .card-wrapper .card-container .card-dtl .businessman-name {
    font-size: 26px;
    padding: 12px 0 6px;
  }
  .card-wrapper .card-container .card-dtl h6 {
    font-size: 18px;
    padding-bottom: 4px;
  }
  .card-wrapper .card-container .card-contact .contact-items .card-item {
    height: 120px;
  }
  .card-wrapper
    .card-container
    .card-contact
    .contact-items
    .card-item
    .icon-img {
    width: 50px;
  }
  .card-wrapper .card-container .card-contact .contact-items .card-item p {
    font-size: 16px;
    padding-top: 6px;
  }
  .card-wrapper .card-follow {
    max-width: 575px;
    margin: auto;
  }
  .card-wrapper .card-follow .follow-me-btn {
    font-size: 18px;
    padding: 3px 24px;
    margin-top: -42px;
  }
  .card-wrapper .card-follow .social-icons {
    padding: 23px 22px 17px;
    gap: 10px;
  }
  .card-wrapper .card-follow.active .social-icons {
    margin-top: -14px;
  }
  .card-wrapper .card-follow .social-icons .social-item img {
    width: 28px;
  }
  /* #inquiry-form.modal .modal-dialog .modal-content .modal-title {
    font-size: 24px;
    padding: 12px 30px 0;
  } */
  /* .btn-close {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-size: 14px;
  } */
  /* .modal-dialog {
    max-width: 460px;
  } */
  /* #inquiry-form.modal .modal-dialog .modal-content {
    border-radius: 20px;
  }
  #inquiry-form.modal
    .modal-dialog
    .modal-content
    .modal-body
    form
    .form-item
    label {
    font-size: 18px;
    margin-bottom: 4px;
  }
  #inquiry-form.modal
    .modal-dialog
    .modal-content
    .modal-body
    form
    .form-item
    .form-control {
    font-size: 18px;
    padding: 8px 20px;
    border-radius: 21px;
  }
  #inquiry-form.modal .modal-dialog .modal-content .modal-body {
    padding: 20px;
  }
  #inquiry-form.modal .modal-dialog .modal-content .modal-body form .form-item {
    margin-bottom: 20px;
  } */
  .inquiry-modal .modal-content form .form-item .form-control {
    font-size: 18px;
    padding: 10px 20px;
    letter-spacing: 1px;
  }
  .inquiry-modal .modal-content form .form-item {
    margin-bottom: 18px;
  }
  .inquiry-modal .modal-content .modal-header .btn-close {
    font-size: 20px;
    top: 26px;
    right: 26px;
  }
  .theme-btn {
    font-size: 18px;
    padding: 8px 30px;
    transition: all 0.3s ease-in-out;
  }
  .theme-btn:hover {
    color: var(--theme-color);
  }
  .about-wrapper {
    max-width: 575px;
    right: 0;
    margin: auto;
    padding: 12px 30px 30px;
  }
  .about-wrapper .theme-ttl {
    margin-bottom: 25px;
  }
  .about-wrapper .profile {
    padding-bottom: 35px;
  }
  .about-wrapper .profile img {
    width: 200px;
    height: 200px;
    border-radius: 25px;
  }
  .about-wrapper .about-dtl p {
    font-size: 18px;
    padding-bottom: 16px;
  }
  .about-wrapper .about-dtl .theme-btn {
    margin-top: 20px;
  }
  .overlay {
    max-width: 575px;
    right: 0;
    margin: auto;
  }
  /* .modals {
    width: calc(575px - 50px);
  }
  .modals .modal-slider {
    border-radius: 26px;
  }
  .modals .btn-close {
    top: -10px;
    right: -10px;
  } */
}

/*  Second Card  */
.second-card .card-wrapper .branch-wrapper,
.second-card .card-wrapper .card-follow {
  /* display: none; */
}
.second-card .card-wrapper .card-container {
  padding: 0;
  background-color: transparent;
}
.second-card .card-wrapper .card-container .card-dtl {
  background-image: url("../assets/images1/background.png");
  padding: 8vw 5vw 12vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.second-card .card-wrapper .card-container .card-dtl .businessman-name,
.card-wrapper .card-container .card-dtl h6 {
  color: var(--theme-color);
}
.second-card .card-wrapper .card-container .card-contact {
  padding: 5vw 5vw 8vw;
}
.second-card
  .card-wrapper
  .card-container
  .card-contact
  .contact-items
  .card-item
  .icon-img {
  width: 9.4vw;
}
.second-card .btn-close {
  background-color: var(--theme-color);
  border: 1px solid var(--primary-color);
}
.second-card
  #inquiry-form.modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .form-item
  .form-control {
  border: 1px solid var(--primary-color);
}

@media (min-width: 575px) {
  .second-card .card-wrapper .card-container .card-dtl {
    padding: 40px 20px 50px;
  }
  .second-card .card-wrapper .card-container .card-contact {
    padding: 30px 20px 30px;
  }
  .second-card
    .card-wrapper
    .card-container
    .card-contact
    .contact-items
    .card-item
    .icon-img {
    width: 50px;
  }
}

/* Share Popup */

.whatsapp-Bgcolor {
  background-color: #25d366 !important;
  border: rgb(37, 211, 102);
  width: 2rem;
}
.whatsapp-Bgcolor:hover {
  background-color: #1fa750 !important;
  border: #1fa750;
  width: 2rem;
}
.facebook-Bgcolor {
  background-color: #4267b2 !important;
  border: #4267b2;
  width: 2rem;
}
.facebook-Bgcolor:hover {
  background-color: #38599b !important;
  border: #38599b;
  width: 2rem;
}
.instagram-Bgcolor {
  background-color: #e1306c !important;
  border: #e1306c;
  width: 2rem;
}
.instagram-Bgcolor:hover {
  background-color: #bd2759 !important;
  border: #bd2759;
  width: 2rem;
}
.Email-Bgcolor {
  background-color: #ea4335 !important;
  border: #ea4335;
  width: 2rem;
}
.Email-Bgcolor:hover {
  background-color: #b6372c !important;
  border: #b6372c;
  width: 2rem;
}
.Telegram-Bgcolor {
  background-color: #4285f4 !important;
  border: #4285f4;
  width: 2rem;
}
.Telegram-Bgcolor:hover {
  background-color: #3465b3 !important;
  border: #3465b3;
  width: 2rem;
}
.Twitter-Bgcolor {
  background-color: #1da1f2 !important;
  border: #1da1f2;
  width: 2rem;
}
.Twitter-Bgcolor:hover {
  background-color: #2186c5 !important;
  border: #2186c5;
  width: 2rem;
}
.Message-Bgcolor {
  background-color: #006aff !important;
  border: #006aff;
  width: 2rem;
}
.Message-Bgcolor:hover {
  background-color: #1466d8 !important;
  border: #1466d8;
  width: 2rem;
}

share-Bgcolor {
  background-color: #dd4b39 !important;
  border: #dd4b39;
}

.share:hover {
  background-color: #b84132 !important;
  border: #b84132;
}

.phoneinput {
  border: none;
}

.PhoneInputInput {
  height: 40px;
  border-radius: 12px;
  border: none;
  outline: none;
}

/* Share Popup icon style */

.iconcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-b-radius {
  border-radius: 100%;
  height: 55px;
}
