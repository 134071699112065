@import "./assets/scss/app.scss";

// * {
//   -ms-overflow-style: none;
// }
// ::-webkit-scrollbar {
//   display: none;
// }

body {
  overflow-y: scroll;
  width: 100%;
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/** date picker style */

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: flex;
  flex-wrap: wrap;
}

.container-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
}

.remove-button,
.add-button {
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.count {
  font-size: 20px;
}

.height{
  height: 42px !important;
  width: 500px;
  max-width: 112px !important;
}